import { isValidUrl, renderMessage } from "../common";
import isNumber from "../common/isNumber";
import loadTwitterJsIfNecessary from "./loadTwitterWidget";

export const allowedHosts = ["twitter.com", "x.com"];
export const twitterUrlMatcher =
  /https?:\/\/[^/]+\/[^/]+\/[^?/]+\/(?<tweetId>[^?/]+)/;

const isAllowedHost = (instanceUrl: string) =>
  allowedHosts.length === 0 || allowedHosts.indexOf(instanceUrl) !== -1;

const getTweetIdFromUrl = (
  urlOrId: string | null,
  isUrl: boolean,
  isId: boolean
) => {
  if (isId) {
    return Number(urlOrId);
  }
  if (urlOrId && isUrl) {
    const { tweetId } = twitterUrlMatcher.exec(urlOrId)?.groups ?? {};
    if (isNumber(tweetId)) {
      return Number(tweetId);
    }
  }

  return null;
};

const getUrlForTweet = (urlOrId: any, isUrl: boolean, isId: boolean) => {
  if (isUrl) {
    const tmpUrl = new URL(urlOrId.split("?")[0]);
    if (tmpUrl.host !== "twitter.com") {
      tmpUrl.host = "twitter.com";
    }
    return tmpUrl;
  }
  if (isId) {
    return new URL(`https://twitter.com/1/status/${urlOrId}`);
  }

  return null;
};

const createTweetContainer = (url: string) => {
  const tweetContainer = document.createElement("blockquote");
  tweetContainer.classList.add("twitter-tweet");
  tweetContainer.setAttribute("lang", "de");
  tweetContainer.setAttribute("data-dnt", "true");
  const tweetParagraph = document.createElement("p");
  tweetParagraph.setAttribute("lang", "en");
  tweetParagraph.setAttribute("dir", "ltr");
  tweetParagraph.textContent = "Lade Tweet...";
  const tweetLink = document.createElement("a");
  tweetLink.href = url;
  tweetLink.style.display = "none";
  tweetContainer.appendChild(tweetParagraph);
  tweetContainer.appendChild(tweetLink);

  return tweetContainer;
};

export const renderTweet = async ($container: Element) => {
  if (!$container || $container.getAttribute("data-rendered") === "true")
    return;
  $container.setAttribute("data-rendered", "true");

  const urlOrId = $container.getAttribute("data-src");
  const isUrl = isValidUrl(urlOrId);
  const isId = isNumber(urlOrId);
  // @ts-ignore
  const tweetId = getTweetIdFromUrl(urlOrId, isUrl, isId);
  const tweetUrl = getUrlForTweet(urlOrId, isUrl, isId);
  if (!tweetUrl) {
    renderMessage($container, urlOrId, "Provided URL or Id is not valid.");
    return;
  }

  if (!isAllowedHost(tweetUrl.host)) {
    renderMessage(
      $container,
      urlOrId,
      "Provided host in Tweet URL is not allowed yet. Please contact the admins and request the activation of that host."
    );
    return;
  }

  const url = tweetUrl.toString();
  // fix potential missing link
  const $link = $container.nextElementSibling;
  if ($link) {
    $link.setAttribute("href", url);
    $link.textContent = url;
  }

  loadTwitterJsIfNecessary();
  const tweetContainer = createTweetContainer(url);
  $container.appendChild(tweetContainer);
  if (window.twttr) {
    window.twttr.ready((twttr) => {
      twttr.widgets.load(tweetContainer);
    });
  } else {
    renderMessage(
      $container,
      url,
      "Could not render Tweet, as twitter widget was not loaded or is blocked."
    );
  }
};

export const renderAllTweets = async () => {
  const $container = document.querySelectorAll(
    ".social-media-embed-container[data-src][data-embed-type=twitter]:not([data-rendered=true])"
  );

  if (!$container || !$container.length) {
    return;
  }
  $container.forEach(($c) => renderTweet($c));
};
