const generateId = (iframes: Map<number, HTMLIFrameElement>) => {
  let id = 0;
  let failCount = 0;
  let idBuffer = new Uint32Array(1);
  while (id === 0 || iframes.has(id)) {
    id = crypto.getRandomValues(idBuffer)[0];
    failCount++;
    if (failCount > 100) {
      // give up and assign (easily guessable) unique number if getRandomValues is broken or no luck
      id = -(iframes.size + 1);
      break;
    }
  }

  return id;
};

export default generateId;
