import { htmlToElement, isValidUrl, renderMessage } from "../common";
import generateId from "../common/generateId";

const mastodonEmbeds = new Map();
const mastodonAllowedInstances: string[] = [];
const mastodonBlockedInstances: string[] = [];
const mastodonUrlMatcher = /^(https?:\/\/[^/]+)\/@[^/]+\/[^?/]+/;

const isAllowedMastodonInstance = (instanceUrl: string) =>
  mastodonAllowedInstances.length === 0 ||
  mastodonAllowedInstances.indexOf(instanceUrl) !== -1;

const isBlockedMastodonInstance = (instanceUrl: string) =>
  mastodonBlockedInstances.length !== 0 &&
  mastodonBlockedInstances.indexOf(instanceUrl) !== -1;

const embedMessageHandler = (e: MessageEvent) => {
  const data = e.data || {};

  if (
    typeof data !== "object" ||
    data.type !== "setHeight" ||
    !mastodonEmbeds.has(data.id)
  ) {
    return;
  }

  const embed = mastodonEmbeds.get(data.id);

  if (embed instanceof HTMLIFrameElement) {
    if (!embed || ("source" in e && embed.contentWindow !== e.source)) {
      return;
    }

    embed.height = data.height;
  } else if (embed instanceof HTMLQuoteElement) {
    const iframe = embed.querySelector("iframe");

    if (!iframe || ("source" in e && iframe.contentWindow !== e.source)) {
      return;
    }

    iframe.height = data.height;

    const placeholder = embed.querySelector("a");
    if (!placeholder) {
      return;
    }

    embed.removeChild(placeholder);
  }
};

const renderEmbed = ($target: Element, content: string) => {
  const embed = htmlToElement<HTMLIFrameElement | HTMLQuoteElement>(content);
  if (!embed) {
    return;
  }
  const id = generateId(mastodonEmbeds);
  mastodonEmbeds.set(id, embed);
  $target.append(embed);

  let iframe: HTMLIFrameElement | undefined;
  if (embed instanceof HTMLIFrameElement) {
    iframe = embed;
  } else if (embed instanceof HTMLQuoteElement) {
    const attrEmbedUrl = embed.getAttribute("data-embed-url");
    if (attrEmbedUrl == null) {
      return;
    }
    try {
      const embedUrl = new URL(attrEmbedUrl);
      iframe = document.createElement("iframe");
      iframe.src = embedUrl.toString();
      iframe.width = `${embed.clientWidth}`;
      iframe.height = "0";

      embed.appendChild(iframe);
    } catch {
      renderMessage($target, attrEmbedUrl, "Provided URL is not valid.");
    }
  }
  if (iframe) {
    iframe.allow = "fullscreen";
    // @ts-ignore
    iframe.sandbox = "allow-scripts allow-same-origin allow-popups";
    iframe.style.border = "0";
    iframe.style.overflow = "hidden";
    iframe.style.display = "block";

    iframe.onload = function () {
      iframe.contentWindow?.postMessage(
        {
          type: "setHeight",
          id: id,
        },
        "*"
      );
    };

    // @ts-ignore
    iframe.onload(); // In case the script is executing after the iframe has already loaded
  }
};

export const renderToot = async ($container: Element) => {
  if (!$container || $container.getAttribute("data-rendered") === "true")
    return;
  $container.setAttribute("data-rendered", "true");
  const srcUrl = $container.getAttribute("data-src");

  if (!isValidUrl(srcUrl)) {
    renderMessage($container, srcUrl, "Provided URL is not valid.");
    return;
  }

  const matchedUrlParts = mastodonUrlMatcher.exec(srcUrl);
  if (!matchedUrlParts || matchedUrlParts.length !== 2) {
    renderMessage(
      $container,
      srcUrl,
      "Provided URL is not in expected format."
    );
    return;
  }
  const [url, mastodonInstanceUrl] = matchedUrlParts;
  mastodonUrlMatcher.lastIndex = 0; // ensure we can reuse the matcher

  if (!isAllowedMastodonInstance(mastodonInstanceUrl)) {
    renderMessage(
      $container,
      srcUrl,
      "Mastodon instance provided in URL is not allowed yet. Please contact the admins and request the activation of that instance."
    );
    return;
  }
  if (isBlockedMastodonInstance(mastodonInstanceUrl)) {
    renderMessage(
      $container,
      srcUrl,
      "Mastodon instance provided in URL is blocked."
    );
    return;
  }

  const response = await fetch(
    `${mastodonInstanceUrl}/api/oembed?url=${url}&maxwidth=550`,
    {
      referrerPolicy: "no-referrer",
    }
  ).catch((_) => {});
  if (!response || response.status !== 200) {
    renderMessage(
      $container,
      srcUrl,
      "Mastodon instance responded with error or response was empty."
    );
    return;
  }

  try {
    const responseJson = await response.json();
    renderEmbed($container, responseJson.html);
  } catch {
    renderMessage(
      $container,
      srcUrl,
      "Could not render response from mastodon server."
    );
    return;
  }
};

export const renderAllToots = async () => {
  const $container = document.querySelectorAll(
    ".social-media-embed-container[data-src][data-embed-type=mastodon]:not([data-rendered=true])"
  );

  if (!$container || !$container.length) {
    return;
  }
  $container.forEach(($c) => renderToot($c));
};

window.addEventListener("message", embedMessageHandler);
