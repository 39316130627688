import { htmlToElement, isValidUrl, renderMessage } from "../common";
import generateId from "../common/generateId";

const mastodonIframes = new Map();
const mastodonAllowedInstances: string[] = [];
const mastodonBlockedInstances: string[] = [];
const mastodonUrlMatcher = /^(https?:\/\/[^/]+)\/@[^/]+\/[^?/]+/;

const isAllowedMastodonInstance = (instanceUrl: string) =>
  mastodonAllowedInstances.length === 0 ||
  mastodonAllowedInstances.indexOf(instanceUrl) !== -1;

const isBlockedMastodonInstance = (instanceUrl: string) =>
  mastodonBlockedInstances.length !== 0 &&
  mastodonBlockedInstances.indexOf(instanceUrl) !== -1;

const setHeightMessageHandler = (e: MessageEvent) => {
  const data = e.data || {};

  if (
    typeof data !== "object" ||
    data.type !== "setHeight" ||
    !mastodonIframes.has(data.id)
  ) {
    return;
  }

  const iframe = mastodonIframes.get(data.id);

  if (!iframe || ("source" in e && iframe.contentWindow !== e.source)) {
    return;
  }

  iframe.height = data.height;
};

const renderIframe = ($target: Element, content: string) => {
  const $iframe = htmlToElement<HTMLIFrameElement>(content);
  if (!$iframe) {
    return;
  }

  const id = generateId(mastodonIframes);

  mastodonIframes.set(id, $iframe);
  //$iframe.scrolling = "no";
  //$iframe.style.overflow = "hidden";
  $iframe.onload = function () {
    $iframe.contentWindow?.postMessage(
      {
        type: "setHeight",
        id,
      },
      "*"
    );
  };
  $target.append($iframe);
};

export const renderToot = async ($container: Element) => {
  if (!$container || $container.getAttribute("data-rendered") === "true")
    return;
  $container.setAttribute("data-rendered", "true");
  const srcUrl = $container.getAttribute("data-src");

  if (!isValidUrl(srcUrl)) {
    renderMessage($container, srcUrl, "Provided URL is not valid.");
    return;
  }

  const matchedUrlParts = mastodonUrlMatcher.exec(srcUrl);
  if (!matchedUrlParts || matchedUrlParts.length !== 2) {
    renderMessage(
      $container,
      srcUrl,
      "Provided URL is not in expected format."
    );
    return;
  }
  const [url, mastodonInstanceUrl] = matchedUrlParts;
  mastodonUrlMatcher.lastIndex = 0; // ensure we can reuse the matcher

  if (!isAllowedMastodonInstance(mastodonInstanceUrl)) {
    renderMessage(
      $container,
      srcUrl,
      "Mastodon instance provided in URL is not allowed yet. Please contact the admins and request the activation of that instance."
    );
    return;
  }
  if (isBlockedMastodonInstance(mastodonInstanceUrl)) {
    renderMessage(
      $container,
      srcUrl,
      "Mastodon instance provided in URL is blocked."
    );
    return;
  }

  const response = await fetch(
    `${mastodonInstanceUrl}/api/oembed?url=${url}&maxwidth=550`,
    {
      referrerPolicy: "no-referrer",
    }
  ).catch((_) => {});
  if (!response || response.status !== 200) {
    renderMessage(
      $container,
      srcUrl,
      "Mastodon instance responded with error or response was empty."
    );
    return;
  }

  try {
    const responseJson = await response.json();
    renderIframe($container, responseJson.html);
  } catch {
    renderMessage(
      $container,
      srcUrl,
      "Could not render response from mastodon server."
    );
    return;
  }
};

export const renderAllToots = async () => {
  const $container = document.querySelectorAll(
    ".social-media-embed-container[data-src][data-embed-type=mastodon]:not([data-rendered=true])"
  );

  if (!$container || !$container.length) {
    return;
  }
  $container.forEach(($c) => renderToot($c));
};

window.addEventListener("message", setHeightMessageHandler);
