const isValidUrl = (candidate: unknown): candidate is string => {
  try {
    const url = new URL(candidate as string);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (_) {
    return false;
  }
};

export default isValidUrl;
