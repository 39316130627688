const renderMessage = (
  $target: Element,
  url: string | null,
  message: string
) => {
  const $p = document.createElement("p");
  const $br = document.createElement("br");
  const $toot = document.createElement("pre");

  $p.textContent = message;
  $toot.textContent = url;

  $p.append($br);
  $p.append($toot);
  $target.append($p);
  $target.setAttribute("data-rendered", "true");
};

export default renderMessage;
