type TwitterFunction = (twttr: {
  widgets: {
    createTweet: (
      tweetId: string,
      targetEl: Element,
      options?: {
        cards?: "hidden";
        conversation?: "none";
        theme?: "light" | "dark";
        width?: number;
        align?: "left" | "center" | "right";
        dnt?: true;
      }
    ) => void;
    load: (c?: Element) => void;
  };
}) => void;

type TwitterWidget = {
  _e: TwitterFunction[];
  ready: (f: TwitterFunction) => void;
};

declare global {
  interface Window {
    twttr?: TwitterWidget;
  }
}

const loadTwitterJsIfNecessary = () => {
  window.twttr = (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0],
      t = window.twttr || ({} as TwitterWidget);
    if (d.getElementById(id)) return t;
    js = d.createElement(s) as HTMLScriptElement;
    js.id = id;
    js.src = "https://platform.twitter.com/widgets.js";
    fjs.parentNode?.insertBefore(js, fjs);

    t._e = [];
    t.ready = function (f) {
      t._e.push(f);
    };

    return t;
  })(document, "script", "twitter-wjs");
};

export default loadTwitterJsIfNecessary;
