import { renderAllSkeets, renderSkeet } from "./bluesky/bluesky";
import { isElement } from "./common";
import { renderAllToots, renderToot } from "./mastodon";
import { renderAllTweets, renderTweet } from "./twitter";

const observeMutations: MutationCallback = (mutations) => {
  if (!Array.isArray(mutations)) {
    return;
  }
  mutations.forEach((mutation) => {
    if (!(mutation instanceof MutationRecord)) {
      return;
    }
    const { type, addedNodes } = mutation;
    if (type !== "childList" || !addedNodes.length) return;
    addedNodes.forEach((addedNode) => {
      if (
        isElement(addedNode) &&
        addedNode.classList.contains("social-media-embed-container")
      ) {
        switch (addedNode.getAttribute("data-embed-type")) {
          case "mastodon":
            renderToot(addedNode);
            break;
          case "twitter":
            renderTweet(addedNode);
            break;
          case "bluesky":
            renderSkeet(addedNode);
            break;
          default:
            break;
        }
      }
    });
  });
};

const renderAll = () => {
  renderAllToots();
  renderAllTweets();
  renderAllSkeets();
};

if ("MutationObserver" in window) {
  const observer = new MutationObserver(observeMutations);
  observer.observe(document, {
    subtree: true,
    childList: true,
  });
}

if (["interactive", "complete"].indexOf(document.readyState) !== -1) {
  renderAll();
} else {
  document.addEventListener("DOMContentLoaded", renderAll);
}
