import { generateId, renderMessage } from "../common";

const EMBED_URL = "https://embed.bsky.app";
const blueskyIframes = new Map();
const allowedHosts = ["bsky.app"];

const isAllowedHost = (instanceUrl: string) =>
  allowedHosts.length === 0 || allowedHosts.indexOf(instanceUrl) !== -1;

const isValidUrl = (candidate: string | null) => {
  if (!candidate) {
    return undefined;
  }
  try {
    const url = new URL(candidate);
    const protocolMatcher = /^https?:$/;
    if (protocolMatcher.test(url.protocol) && isAllowedHost(url.host)) {
      return url;
    }
  } catch {}
  return undefined;
};

const resolveHandleAndPostId = (url: URL) => {
  if (!url) {
    return undefined;
  }
  const urlMatcher = /^\/profile\/(?<handle>[^?/]+)\/post\/(?<postId>[^/]+)$/;
  const matches = urlMatcher.exec(url.pathname);
  if (!matches?.groups) {
    return undefined;
  }
  const { handle, postId } = matches.groups;

  return { baseUrl: url.origin, handle, postId };
};

const setHeightMessageHandler = (e: MessageEvent) => {
  if (e.origin !== EMBED_URL) {
    return;
  }
  const data = e.data || {};

  if (
    typeof data !== "object" ||
    typeof data.id !== "string" ||
    typeof data.height !== "number"
  ) {
    return;
  }

  const { id, height } = data;
  const iframe = blueskyIframes.get(Number(id));

  if (!iframe || ("source" in e && iframe.contentWindow !== e.source)) {
    return;
  }

  iframe.height = height;
};

const renderIframe = ($target: Element, did: string, postId: string) => {
  const id = generateId(blueskyIframes);
  const iframe = document.createElement("iframe");
  iframe.src = `${EMBED_URL}/embed/${did}/app.bsky.feed.post/${postId}?id=${id}`;
  iframe.width = "100%";
  iframe.style.border = "none";
  iframe.style.display = "block";
  iframe.style.flexGrow = "1";
  iframe.frameBorder = "0";
  iframe.scrolling = "no";
  const container = document.createElement("div");
  container.style.maxWidth = "550px";
  container.style.width = "100%";
  container.style.marginTop = "10px";
  container.style.marginBottom = "10px";
  container.style.display = "flex";
  container.className = "bluesky-embed";

  blueskyIframes.set(id, iframe);
  container.appendChild(iframe);
  $target.appendChild(container);
};

const resolveDid = async (handleOrDid?: string) => {
  if (handleOrDid == null) {
    return {};
  }

  if (handleOrDid.startsWith("did")) {
    return { did: handleOrDid };
  }

  const response = await fetch(
    `https://public.api.bsky.app/xrpc/com.atproto.identity.resolveHandle?handle=${handleOrDid}`,
    {
      referrerPolicy: "no-referrer",
    }
  ).catch((_) => {});
  if (!response || response.status !== 200) {
    return {};
  }

  return response.json() as Promise<{ did?: string }>;
};

export const renderSkeet = async ($container: Element) => {
  if (!$container || $container.getAttribute("data-rendered") === "true")
    return;
  $container.setAttribute("data-rendered", "true");

  const dataSrc = $container.getAttribute("data-src");
  const url = isValidUrl(dataSrc);

  if (!url) {
    renderMessage($container, dataSrc, "Provided URL is not valid.");
    return;
  }

  const { baseUrl, handle, postId } = resolveHandleAndPostId(url) ?? {};

  if (!baseUrl || !handle) {
    renderMessage(
      $container,
      dataSrc,
      "Could not resolve user handle from provided URL."
    );
    return;
  }

  if (!postId) {
    renderMessage(
      $container,
      dataSrc,
      "Could not resolve postId from provided URL."
    );
    return;
  }

  try {
    const { did } = await resolveDid(handle);
    if (!did || typeof did !== "string") {
      renderMessage(
        $container,
        dataSrc,
        `Could not resolve "did" from handle: ${handle}.`
      );
      return;
    }

    renderIframe($container, did, postId);
  } catch (e) {
    console.log(e);
    renderMessage($container, dataSrc, "Could not render Bluesky post.");
  }
};

export const renderAllSkeets = async () => {
  const $container = document.querySelectorAll(
    ".social-media-embed-container[data-src][data-embed-type=bluesky]:not([data-rendered=true])"
  );

  if (!$container || !$container.length) {
    return;
  }
  $container.forEach(($c) => renderSkeet($c));
};

window.addEventListener("message", setHeightMessageHandler);
